<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Browse" subtitle="See all those who hold a badge either through registration or assignment.">

		<app-content-head-actions-panel v-if="$permissions.badges.holders.codes.enabled && collection.length" text="Codes" icon="qrcode" component="manager/common/panel/Sheet" :data="sheetData.code" />
		<app-content-head-actions-panel v-if="$permissions.badges.holders.labels.enabled && collection.length" text="Badges" icon="badge" component="manager/common/panel/Sheet" :data="sheetData.badge" />
		<app-content-head-actions-panel v-if="$authorised('con/badges/access', 'write') && $permissions.badges.holders.import.enabled" :divide="collection.length" text="Import" icon="import" component="manager/common/panel/Import" :data="importexportData" />
		<app-content-head-actions-panel v-if="$permissions.badges.holders.export.enabled && collection.length" text="Export" icon="export" component="manager/common/panel/Export" :data="importexportData" />
		<app-content-head-actions-item v-if="$authorised('con/badges/access', 'write') && $permissions.badges.holders.create.enabled" :divide="true" text="Create" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item v-if="$authorised('con/badges/access', 'write') && $permissions.badges.holders.delete.enabled" text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-checklist v-if="$permissions.badges.groups.enabled && references.groups.length" v-model="filter.groups" text="Group" tooltip="Filter by group" :options="filterGroupOptions" />
		<app-content-filters-checklist v-if="$convention.setup.service.registration" v-model="filter.status" text="Status" tooltip="Filter by status" :options="filterStatusOptions" />
		<app-content-filters-checklist v-if="$permissions.badges.types.enabled && references.types.length" v-model="filter.types" text="Type" tooltip="Filter by type" :options="filterTypeOptions" />
		<app-content-filters-checklist v-if="$convention.setup.service.registration" v-model="filter.method" text="Method" tooltip="Filter by method" :options="filterMethodOptions" />
		<app-content-filters-date v-model="filter.from" tooltip="Filter from date" text="From" :max="filter.to" />
		<app-content-filters-date v-model="filter.to" tooltip="Filter to date" text="To" :min="filter.from" />
		<app-content-filters-select v-model="filter.sort" :alwaysLabel="true" :alwaysValue="true" text="Sort by" tooltip="Change sorting" :options="filterSortOptions" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no badge holders found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="No." />
				<app-table-column text="Ref." />
				<app-table-column v-if="$convention.setup.service.registration" text="Status" />
				<app-table-column :sorted="filter.sort === 'name'" text="Name" />
				<app-table-column v-if="$permissions.badges.types.enabled" text="Type" />
				<app-table-column v-if="$convention.setup.service.registration" text="Method" />
				<app-table-column :sorted="filter.sort === 'date'" text="Date" />
				<app-table-column v-if="$permissions.badges.holders.codes.enabled" />
				<app-table-column v-if="$permissions.badges.holders.labels.enabled" />
				<app-table-column v-if="$permissions.badges.holders.delete.enabled" />

			</app-table-head>

			<app-table-body>

				<app-table-row align="top" :selected="isSelected(item.id)" :warning="!item.status" v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.reference" />
					<app-table-cell-text v-if="$convention.setup.service.registration" :text="item.referenceRandom" />
					<app-table-cell-text v-if="!$convention.setup.service.registration" :text="item.referenceExternal" />
					<app-table-cell-text v-if="$convention.setup.service.registration" :text="references.status[item.status]" />
					<app-table-cell-text :text="item.name" :subtext="item.group.name" />
					<app-table-cell-text v-if="$permissions.badges.types.enabled && !item.stacked" :text="item.type.name" />
					<app-table-cell-text v-if="$permissions.badges.types.enabled && item.stacked" :text="'Stacked x' + item.count.stacked" icon="badges.stacked" :icon-tooltip="'This badge represents ' + item.count.stacked + ' badge types stacked together.'" />
					<app-table-cell-text v-if="$convention.setup.service.registration" :text="references.methods[item.method]" />
					<app-table-cell-text :text="item.date | localDate('DD MMMM YYYY')" />
					<app-table-cell-action v-if="$permissions.badges.holders.codes.enabled" icon="qrcode" v-tooltip="'View QR Code'" v-on:click="onQRClick(item.id)" />
					<app-table-cell-action v-if="$permissions.badges.holders.labels.enabled" icon="badge" v-tooltip="'View Badge'" v-on:click="onBadgeClick(item.id)" />
					<app-table-cell-select v-if="$permissions.badges.holders.delete.enabled" :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			references: {
				types: [],
				sorts: [],
				status: [],
				groups: [],
				methods: []
			},
			filter: {
				types: [],
				status: [],
				groups: [],
				sort: 'date',
				method: [],
				from: 0,
				to: 0
			},
			endpoint: 'convention/badges/holders',
			live: 'convention/badges/holders',
			edit: 'Convention.Badges.Holders.Edit'
		}

	},

	created: function() {

		this.$pubsub.$on('imported', this.load.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('imported', this.load.bind(this))

	},

	computed: {

		layout: function() {

			return this.$layout([
				'48px',
				'72px',
				['72px', this.$convention.setup.service.registration],
				'auto',
				['200px', this.$permissions.badges.types.enabled],
				['125px', this.$convention.setup.service.registration],
				'150px',
				['24px', this.$permissions.badges.holders.codes.enabled],
				['24px', this.$permissions.badges.holders.labels.enabled],
				['24px', this.$permissions.badges.holders.delete.enabled]
			])

		},
		
		importexportData: function() {

			var columns = {}
			var required = []
			var mapping = {}

			if (this.$convention.setup.service.registration) {

				columns['reference'] = 'Number'
				columns['referenceRandom'] = 'Reference'
				columns['status'] = 'Status'

				required.push('reference')

			} else {

				columns['referenceExternal'] = 'Reference'

				required.push('referenceExternal')

			}

			columns['firstname'] = 'First name'
			columns['lastname'] = 'Last name'

			required.push('firstname')
			required.push('lastname')

			if (this.$permissions.badges.holders.logins.enabled) {

				columns['email'] = 'Email'

				required.push('email')

			}

			if (this.$convention.setup.service.registration) {

				columns['type'] = 'Type'
				columns['method'] = 'Method'

				mapping = {
					type: this.filterTypeOptions,
					method: this.filterMethodOptions
				}
				
				required.push('type')

			}

			columns['date'] = 'Date'

			return {
				columns: columns,
				requiredColumns: required,
				valueMapping: mapping,
				params: this.getParams(),
				index: this.selectedIndex,
				endpoint: this.endpoint
			}

		},

		sheetData: function() {

			return {
				code: {
					title: 'qr code',
					endpoint: 'qr',
					index: this.selectedIndex,
					perpage: this.pagination.perpage
				},
				badge: {
					title: 'badge',
					endpoint: 'badge',
					index: this.selectedIndex,
					perpage: this.pagination.perpage
				}
			}

		},

		filterTypeOptions: function() {

			var options = []

			this.$_.each(this.references.types, function(type) {

				options.push({
					value: type.id,
					text: type.name
				})
			
			})

			return this.$_.sortBy(options, 'text')

		},

		filterGroupOptions: function() {

			var options = []

			this.$_.each(this.references.groups, function(type) {

				options.push({
					value: type.id,
					text: type.name
				})
			
			})

			return this.$_.sortBy(options, 'text')

		},

		filterMethodOptions: function() {

			var options = []

			this.$_.each(this.references.methods, function(name, id) {

				options.push({
					value: id,
					text: name
				})
			
			})

			return this.$_.sortBy(options, 'text')

		},

		filterSortOptions: function() {

			var options = []

			this.$_.each(this.references.sorts, function(text, value) {

				options.push({
					value: value,
					text: text
				})
			
			})

			return this.$_.sortBy(options, 'text')

		},

		filterStatusOptions: function() {

			var options = []

			this.$_.each(this.references.status, function(text, value) {

				options.push({
					value: value,
					text: text
				})
			
			})

			return this.$_.sortBy(options, 'text')

		}

	},

	methods: {

		onQRClick: function(id) {

			this.is.loading = true

			this.$api.get(['qr', id]).then(function(json) {

				this.$present(json.qr)

				this.is.loading = false

			}.bind(this))

		},

		onBadgeClick: function(id) {

			this.is.loading = true

			this.$api.get(['badge', id]).then(function(json) {

				this.$present(json.badge)

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>